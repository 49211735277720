<template>
  <div>
    <Reports />
  </div>
</template>

<script>
import Reports from '@/modules/reports/Reports.vue'

export default {
  components: {
    Reports,
  },
}
</script>

<style>

</style>
