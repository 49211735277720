<template>
  <div>
    <h6>
      Escolha o tipo de relatório
    </h6>
    <div class="reports-card-list">
      <b-row>
        <b-col
          v-for="report in reports"
          :key="report.name"
          @click="openDownloadReport(report)"
        >
          <CardReports
            :report="report"
          />
        </b-col>
        <VSModal
          name="DownloadReport"
          :properties="reportModal"
        />
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import CardReports from './CardReports.vue'
import VSModal from '@/components/modals/VSModal.vue'

export default {
  components: {
    CardReports,
    BRow,
    BCol,
    VSModal,
  },
  data() {
    return {
      reportModal: {},
      reports: [
        { name: 'Relatório de licenças e condicionantes', type: 'licensingConditionsManagement', process: 'Licença e Condicionantes' },
        { name: 'Relatório de processos de licenciamentos', type: 'licensingConditionsManagement', process: 'Licenciamento' },
        { name: 'Gestão de Documentos - Processos de licenciamento', type: 'licensing', process: 'Licenciamento' },
        { name: 'Gestão de Condicionantes - Licenças', type: 'licensingConditions', process: 'Licença e Condicionantes' },
        { name: 'Relatório de gestão de resíduos', type: 'wasteManagement', process: 'Resíduos' },
        { name: 'Relatório de Resíduos', type: 'waste', process: 'Resíduos' },
      ],
    }
  },
  methods: {
    openDownloadReport(report) {
      this.reportModal = report
      this.showModal('DownloadReport')
    },
  },
}
</script>

<style lang="scss" scoped>
.reports-card-list {
  display: flex;
}

</style>
