<template>
  <b-card
    class="card-report"
    body-class="card-report-body"
  >
    <VSIcons
      name="DocumentText"
      color="#33383C"
      class="card-report-icon"
    />
    <div class="card-report-linedivider" />
    <span class="card-report-name">{{ report.name }}</span>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    BCard,
    VSIcons,
  },
  props: {
    report: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss">
.card-report {
  border-color: transparent !important;
  border-radius: 9px;
  box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);
  min-width: 20rem;
  max-width: 25rem;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    .card-report {
      &-name {
        color: #A0AEB9;
      }
    }
    path {
      stroke: #A0AEB9;
;
    }
  }
  &-body {
    display: flex;
    align-items: center;
  }

  &-icon {
    background-color: rgba(160, 174, 185, 0.21);
    min-height: 55px;
    min-width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    svg {
      path {
        stroke: #33383C;
      }
    }
  }
  &-name {
    font-size: 15px;
    letter-spacing: 0;
  }

  .card-report-linedivider {
    width: 1px;
    height: 3rem;
    border-right: 1px solid rgba(17, 17, 17, 0.08);
    margin: 0 1rem;
  }
}

</style>
