<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab
        title="Relatórios"
        active
      >
        <TabReports />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import TabReports from './TabReports.vue'

export default {
  components: {
    BTabs,
    BTab,

    TabReports,
  },
}
</script>
